import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { TagCloud } from 'react-tagcloud';
import './ISP.css';
import logoImage from '../assets/Logo.png';

const ISP = () => {
    const navigate = useNavigate();
    const ISPs = [
        { value: "DIL", count: 2 },
        { value: "IQ", count: 7 },
        { value: "ارض السواد", count: 1 },
        { value: "افق السماء", count: 1 },
        { value: "افق السماء/سمفوني", count: 0 },
        { value: "افق المدى", count: 13 },
        { value: "الجزيرة", count: 0 },
        { value: "الحياة", count: 1 },
        { value: "الخلية الفائقة", count: 1 },
        { value: "الرحمة", count: 1 },
        { value: "السرد", count: 0 },
        { value: "اللون الأخضر", count: 6 },
        { value: "المجهز", count: 0 },
        { value: "المشرق", count: 0 },
        { value: "النسر", count: 0 },
        { value: "تاليا", count: 1 },
        { value: "توروس ئارم", count: 36 },
        { value: "حلم المستقبل", count: 100 },
        { value: "سرعة العراق", count: 1 },
        { value: "شبكة الأرض", count: 0 },
        { value: "شبكة العراق", count: 0 },
        { value: "طريق الثريا", count: 3 },
        { value: "كوالتي نت", count: 1 },
        { value: "مسارات العراق", count: 8 },
        { value: "نور البداية", count: 2 },
        { value: "هالة الرافدين", count: 11 },
        { value: "هلال الرافدين", count: 1 },
        { value: "ايرثلنك ", count: 73 }
    ];

    const customRenderer = (tag, size, color) => (
        <span
            key={tag.value}
            className="tag-cloud-item"
            style={{
                fontSize: `${size}em`,
                margin: '5px 10px', // Adjust spacing for a looser feel
                padding: '5px 10px', // Add some padding
                display: 'inline-block',
                color: '#97d700', 
                borderRadius: '10px', // Rounded corners
                backgroundColor: 'rgba(151, 215, 0, 0.1)', // Subtle green background
                animation: `float ${Math.random() * 5 + 3}s ease-in-out infinite`,
                cursor: 'pointer', // Change cursor to pointer on hover

            }}
        >
            {tag.value}
        </span>
    );


    const handleHeaderClick = () => {
        navigate('/'); // Redirects to the main page
    };

    return (
        <div className="bg-[#111820] text-[#97d700] font-sans leading-normal tracking-normal">
            <div className="video-header" onClick={handleHeaderClick}>
                <video autoPlay loop muted className="background-video">
                    <source src="/assets/cybernet.mp4" type="video/mp4" />
                </video>
                <div className="overlay"></div>
                <div className="header-content">
                    <img src={logoImage} alt="Resources Technology Logo" className="header-logo" />
                    <h1 className="header-title">Tech Resources</h1>
                </div>
            </div>
            <div className="isp-container mx-auto text-center py-10">
                <TagCloud
                    minSize={1}
                    maxSize={5}
                    tags={ISPs}
                    renderer={customRenderer}
                    className="tag-cloud"
                />
            </div>
            <Footer />
        </div>
    );
}

export default ISP;
