import React, {useEffect, useState} from 'react';
import { BsRouter } from "react-icons/bs";
import Footer from './Footer';
import logoImage from '../assets/Logo.png';
import { useNavigate } from 'react-router-dom';
import { useSprings,useSpring, animated } from 'react-spring';
import iqMap from '../assets/iqmap.svg';
import shield from '../assets/Safeshield.svg'; // Make sure you have this asset
import { Fade } from 'react-reveal';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FaShieldHalved } from "react-icons/fa6";


const AntiDDos = () => {
    const navigate = useNavigate();
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const timelineEvents = [
        { year: 2021, text: "The planning for the cybersecurity project started, laying the foundation for a secure digital future for Iraq.", icon: <BsRouter /> }, // Replace <BsRouter /> with the actual icon you'd like to use
        { year: 2022, text: "Infrastructure for the cybersecurity initiative was laid down, setting the stage for advanced protection measures.", icon: <BsRouter /> },
        { year: 2023, text: "Cloud AntiDDoS was activated, providing robust protection against an array of cyber threats.", icon: <BsRouter /> },
        { year: 2024, text: "In 2024, a comprehensive strategy to bolster Iraq's cybersecurity infrastructure will encompass both cloud-based and on-premise Threat Management Systems (TMSes). This strategic approach underscores our steadfast commitment to fortifying Iraq's cyber defenses.", icon: <BsRouter /> }
    ];
    useEffect(() => {
        window.scrollTo(0, 0);


        const checkOrientation = () => {
            setIsPortrait(window.innerHeight > window.innerWidth);
        };

        window.addEventListener('resize', checkOrientation);
        checkOrientation();  // Initial check

        return () => {
            window.removeEventListener('resize', checkOrientation);
        };

        
    }, []);

    const handleHeaderClick = () => {
        navigate('/'); // Redirects to the main page
      };
    //   const center = { x: '55%', y: '48%' }; // Center of the map for lines to point to
    const center = isPortrait
    ? { x: '55%', y: '49%' } // Adjusted for portrait
    : { x: '55%', y: '48%' }; // Existing for landscape

  // Define your routers with their positions
  const getRouters = (isPortrait) => {
    


    if (isPortrait) {
        return [
            { id: 1, x: '25%', y: '30%' },
            { id: 2, x: '68%', y: '50%' },
            { id: 3, x: '50%', y: '70%' },
            { id: 4, x: '38%', y: '15%' },
            { id: 5, x: '47%', y: '15%' },
            { id: 6, x: '62%', y: '30%' },
            { id: 7, x: '84%', y: '68%' },
        ];
    } else {
        return [
            { id: 1, x: '28%', y: '30%' },
            { id: 2, x: '20%', y: '50%' },
            { id: 3, x: '60%', y: '80%' },
            { id: 4, x: '38%', y: '5%' },
            { id: 5, x: '47%', y: '4%' },
            { id: 6, x: '60%', y: '30%' },
            { id: 7, x: '84%', y: '75%' },
        ];
    }
};
  
const routers = getRouters(isPortrait);

  // Create individual animations for each router
  const [routerSprings] = useSprings(routers.length, index => ({
    from: { opacity: 0, transform: 'scale(0)', color: 'red' },  // Start red
    to: async (next) => {
        // Scale up the router
        await next({ opacity: 1, transform: 'scale(1)' });
        // Wait for the lines to animate and change color
        await new Promise(resolve => setTimeout(resolve, 800 + 200 * index + 1000));
        // Change color of the router to green
        await next({ color: '#438c11' });
    },
    delay: 50 * index, // 50ms delay between each router
}));

 
    // Calculate the angle for each line based on its start and end points
    const calculateAngle = (x1, y1, x2, y2) => {
        const dy = y2 - y1;
        const dx = x2 - x1;
        return Math.atan2(dy, dx) * 180 / Math.PI;
    };

    // Create line animations
    const [lineSprings] = useSprings(routers.length, index => {
        const x1 = parseFloat(routers[index].x);
        const y1 = parseFloat(routers[index].y);
        const x2 = parseFloat(center.x);
        const y2 = parseFloat(center.y);

        const angle = calculateAngle(x1, y1, x2,y2);
        console.log(x2 + " , " + y2);

        return {
            from: { left: `${x1}%`, top: `${y1}%`, width: '0%', backgroundColor: 'red', transform: `rotate(${angle}deg)` }, // Start red
            to: async (next) => {
                // Expand the line to the center
                await next({ width: `${Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2)}%` });
    
                // Wait for 800ms
                await new Promise(resolve => setTimeout(resolve, 800));
    
                // Change color of the line to green
                await next({ backgroundColor: '#97d070' });
    
                // Shrink the line while keeping the end point at the center
                await next({ left: `${x2}%`, top: `${y2}%`, width: '0%', transform: `rotate(${angle}deg) translateX(-50%)` }); 
            },
            delay: 200 * index + 1000, // Delay so lines animate after routers
        };

    });
// Shield animation
const shieldAnimation = useSpring({
    from: { opacity: 0, transform: 'scale(0)' },
    to: { opacity: 1, transform: 'scale(2)' }, // Scale x2
    delay: 200 * routers.length + 1000, // Delay until after all line animations
});
  return (
    <div>
      <div className="video-header" onClick={handleHeaderClick} style={{ height: '250px' }}> {/* Adjusted height */}
                <video autoPlay loop muted className="background-video">
                    <source src="/assets/cybernet.mp4" type="video/mp4" />
                </video>
                <div className="overlay"></div>
                <div className="header-content" style={{ padding: '20px' }}> {/* Adjusted padding */}
                    <img src={logoImage} alt="Logo" className="header-logo" style={{ height: '80px' }} /> {/* Adjusted logo size */}
                    <h1 className="header-title" style={{ fontSize: '24px' }}>Tech Resources</h1> {/* Adjusted font size */}
                </div>
            </div>
    
            <div className="bg-white  flex flex-col " >
                <div className="flex-grow container mx-auto p-0 relative text-center">
                    <Fade delay={400} >
                    <div className="map-container" style={{ position: 'relative', display: 'inline-block', marginBottom: '1px' }}>
                        <img src={iqMap} alt="Iraq Map" style={{ height: '500px', width: 'auto' }}/>
                          {/* Animated routers */}
                          {routerSprings.map((props, index) => (
                            <animated.div
                                key={routers[index].id}
                                style={{
                                    ...props,
                                    position: 'absolute',
                                    left: routers[index].x,
                                    top: routers[index].y,
                                    fontSize: '2rem'
                                }}
                            >
                                <BsRouter />
                            </animated.div>
                        ))}

                        {/* Animated lines */}
                        {lineSprings.map((props, index) => (
                            <animated.div
                                key={routers[index].id}
                                style={{
                                    ...props,
                                    position: 'absolute',
                                    left: routers[index].x,
                                    top: routers[index].y,
                                    background: 'black', // Line color
                                    height: '2px', // Line thickness
                                    transformOrigin: '0% 50%', // Animate from start of the line
                                }}
                            />
                        ))}

                        {/* Animated shield */}
                        <animated.img
                            src={shield}
                            alt="Shield"
                            style={{
                                ...shieldAnimation,
                                position: 'absolute',
                                left: center.x,
                                top: center.y,
                                transform: 'translate(-50%, -50%)',
                                width: '100px', // Adjust as needed
                            }}
                        />
                       

                    </div>
                    </Fade>
                    
                </div>
                

            </div>
            <div className="container mx-auto p-4 text-center" style={{ color: 'white', backgroundColor: '#111820' }}>
                <h2 className="text-center font-bold text-xl mb-4">Our impact at a Glance</h2>
                {[
                    "•First private and public sector collaboration and partnership•",
                    "•3 Tbps of traffic protected across the nation•",
                    "•Encompassing the whole country's digital infrastructure•",
                    "•Protecting all the IGWs with Alsalam and Nokia•",
                    "•Partnered with the #1 in the world in AntiDDoS with cloud capacity protection of 15tbps•",
                    "•Central control entrusted to government specialists•",
                ].map((item, index) => (
                    <Fade key={index} left={index % 2 === 0} right={index % 2 !== 0} delay={index * 500}>
                        <p style={{ fontSize: '1.2em', fontWeight: 'bold', marginBottom: '20px' }}>{item}</p>
                    </Fade>
                ))}
            </div>



            <div className="bg-white ">
                <div className="container mx-auto p-0">
                    <h2 className="text-center font-bold text-xl">Timeline</h2>
                    <VerticalTimeline lineColor="#111820">
                        {timelineEvents.map(event => (
                            <VerticalTimelineElement
                                className="vertical-timeline-element--work"
                                contentStyle={{ background: 'rgb(151, 208, 112)', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid  rgb(151, 208, 112)' }}
                                date={event.year.toString()}
                                iconStyle={{ background: 'rgb(151, 208, 112)', color: '#fff' }}
                                icon={<FaShieldHalved />}
                            >
                                <h3 className="vertical-timeline-element-title">{`Event of ${event.year}`}</h3>
                                <p>{event.text}</p>
                            </VerticalTimelineElement>
                        ))}
                    </VerticalTimeline>
                </div>
            </div>
            <div style={{ backgroundColor: '#111820' }}>
                <Footer />
            </div>
        </div>
    );
};

export default AntiDDos;