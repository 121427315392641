import React from 'react';
import { Link } from 'react-router-dom';
const Requestform = () => {
  return (
    <div className='w-full py-16 text-white px-4' id='contact'>
      <div className='max-w-[95%] mx-auto grid lg:grid-cols-3'>
        <div className='lg:col-span-2 my-4'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>
            Need Cybersecurity Solutions?
          </h1>
          <p>Reach out to us with your requirements and we'll get back to you.</p>
        </div>
        <div className='my-4'>
        <form action="https://formspree.io/f/xleqrjlp" method="POST">
            <div className='flex flex-col items-center justify-between w-full'>
              <input
                className='p-3 flex w-full rounded-md text-black mb-2'
                type='email'
                name='email'  // Add 'name' attribute for the email field
                placeholder='Enter Email'
                required
              />
              <textarea
                className='p-3 flex w-full rounded-md text-black mb-2'
                name='message'  // Add 'name' attribute for the message field
                placeholder='Describe your requirements'
                rows={4}
                required
              />
              <button
                className='bg-[#97d700] text-black rounded-md font-medium w-full sm:w-[200px] ml-0 sm:ml-4 my-6 px-6 py-3'
              >
                Submit Request
              </button>
            </div>
          </form>
          <p>
            We care about the protection of your data. Read our{' '}
            <Link to="/privacy-policy" className='text-[#97d700]'>
    Privacy Policy.
  </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Requestform;
