import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import eagleLogo from "../assets/frontart (3).svg";
// import shieldetail from '../assets/Slogo.svg';
import cloudtech from '../assets/cloudgreen.svg';
import cloudtech2 from '../assets/cloudgreen.svg';

import Fade from "react-reveal/Fade";
import { useSpring, animated } from "react-spring";

const DDosmain = () => {
  const navigate = useNavigate();
  const [scrollY, setScrollY] = useState(0);
  const [scrollPercent, setScrollPercent] = useState(0);
  const eagleRef = useRef(null);
  const [isShieldVisible, setIsShieldVisible] = useState(true);
  const [shieldPosition, setShieldPosition] = useState({ x: 0, y: 0 });


  // Set the initial position of the shield
  useEffect(() => {
    const updateShieldInitialPosition = () => {
      if (eagleRef.current) {
        const eagleRect = eagleRef.current.getBoundingClientRect();
        const eagleCenterX = eagleRect.left;
        const shieldY = -1 * eagleRect.height - 48;
        setShieldPosition({
          x: eagleCenterX,
          y: shieldY,
        });

      }
    };

    const handleScroll = () => {
      const scrollMax = document.documentElement.scrollHeight - window.innerHeight;
      const newScrollPercent = window.scrollY / scrollMax;
      if (isShieldVisible === true) {
        setIsShieldVisible(false);
      }
      setScrollPercent(newScrollPercent);
      const horizontalMovement = Math.min(140, newScrollPercent * 140); // Adjusted to 280 for a wider range
      setScrollY(horizontalMovement);
      setIsShieldVisible(true);
    };
    updateShieldInitialPosition();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateShieldInitialPosition);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", updateShieldInitialPosition);
    };
  }, [isShieldVisible]);


  // In DDosmain.jsx
const handleLearnMoreClick = () => {
  navigate("/anti-ddos"); // This should match the path you set in App.js
}; 


  const eagleStyle = useSpring({
    transform: `scale(${1 + 1.3*scrollPercent})`, // Scales down as the user scrolls
    position: 'relative',
    zIndex: -1 // Ensure the eagle logo is behind the clouds
  });
  const cloudTechStyle = useSpring({
    opacity: 1, // Decreases as the user scrolls down
    transform: `translateX(${-40+shieldPosition.x + (-5*scrollY)}px) translateY(${100+shieldPosition.y}px)`,
    position: 'absolute',
    zIndex: 3 // Ensure cloudtech is above the eagle logo
  });

  const cloudTech2Style = useSpring({
    opacity: 1, // Decreases as the user scrolls down
    transform: `translateX(${(40+shieldPosition.x + 5*scrollY)}px) scaleX(-1) translateY(${100+shieldPosition.y}px)`, // Mirrored and moves to the right
    position: 'absolute',
    zIndex: 3 // Ensure cloudtech2 is above the eagle logo
  });

  return (
    <div className="bg-white relative" style={{  position: "relative", zIndex: 2 }}>
      <Fade>
        <animated.div style={eagleStyle} className="text-center pt-10">
          <img
            ref={eagleRef}
            src={eagleLogo}
            alt="Eagle Logo"
            className="mx-auto w-72 h-80"
          />
        </animated.div>
      </Fade>
      <Fade left opposite delay={20} distance="10px">
        <animated.div style={cloudTechStyle} className="text-center absolute cloudtech-container">
          <img 
            src={cloudtech} 
            alt="Cloud Technology" 
            className="w-72 h-72"
          />
        </animated.div>
      </Fade>
      <Fade right opposite delay={20} distance="10px">
        <animated.div style={cloudTech2Style} className="text-center absolute cloudtech2-container">
          <img 
            src={cloudtech2} 
            alt="Mirrored Cloud Technology" 
            className="w-72 h-72"
          />
        </animated.div>
      </Fade>
      <Fade top>
        <div className="text-center px-4 py-10">
          <h2 className="text-4xl font-bold mb-4">
            Protecting the Entire Nation
          </h2>
          <p className="text-xl mb-8">
            Our major client, The Iraqi Informatics and Telecommunications Public Company
            (ITPC), trusts us to provide anti-DDoS protection across the
            country.
          </p>
          <button
            onClick={handleLearnMoreClick}
            className="bg-[#97d700] text-white font-bold py-2 px-4 rounded hover:bg-green-600 transition duration-300"
          >
            Learn More
          </button>
        </div>
      </Fade>
    </div>
  );
};

export default DDosmain;
