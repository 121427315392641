import React from 'react';
import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
} from 'react-icons/fa';

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
      <div>
        <h1 className='w-full text-3xl font-bold text-[#97d700]'>DDoS Protection Experts</h1>
        <p className='py-4'>Leading the industry with advanced DDoS protection solutions, trusted by top ISPs and governmental bodies.</p>
        <div className='flex justify-between md:w-[75%] my-6'>
            <FaFacebookSquare size={30} />
            <FaInstagram size={30} />
            <FaTwitterSquare size={30} />
            <FaGithubSquare size={30} />
            <FaDribbbleSquare size={30} />
        </div>
      </div>
      <div className='lg:col-span-2 flex justify-between mt-6'>
        <div>
            <h6 className='font-medium text-gray-400'>Services</h6>
            <ul>
                <li className='py-2 text-sm'>DDoS Mitigation</li>
                <li className='py-2 text-sm'>ISP Collaboration</li>
                <li className='py-2 text-sm'>Governmental Projects</li>
                <li className='py-2 text-sm'>Custom Solutions</li>
            </ul>
        </div>
        <div>
            <h6 className='font-medium text-gray-400'>Resources</h6>
            <ul>
                <li className='py-2 text-sm'>Blog</li>
                <li className='py-2 text-sm'>Case Studies</li>
                <li className='py-2 text-sm'>Whitepapers</li>
                <li className='py-2 text-sm'>Research</li>
            </ul>
        </div>
        <div>
            <h6 className='font-medium text-gray-400'>Company</h6>
            <ul>
                <li className='py-2 text-sm'>About Us</li>
                <li className='py-2 text-sm'>Careers</li>
                <li className='py-2 text-sm'>Press</li>
                <li className='py-2 text-sm'>Contact</li>
            </ul>
        </div>
        <div>
            <h6 className='font-medium text-gray-400'>Legal</h6>
            <ul>
                <li className='py-2 text-sm'>Privacy Policy</li>
                <li className='py-2 text-sm'>Terms of Service</li>
                <li className='py-2 text-sm'>Compliance</li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
