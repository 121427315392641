import React, { useState, useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import "./Hero.css";
import { useNavigate } from "react-router-dom";
import logoImage from "../assets/vlogo.png";
import netscoutLogo from '../assets/netscout.svg';

const Hero = () => {
  const phrases = useMemo(
    () => ["DDoS Protection", "Digital Security", "Network Defense"],
    []
  );

  const [text, setText] = useState("");
  const [phraseIndex, setPhraseIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [cursorVisible, setCursorVisible] = useState(true);
  const [isTechResourcesLogo, setIsTechResourcesLogo] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const typeSpeed = isDeleting ? 50 : 150;
    const backSpeed = 50;

    // Manage cursor visibility
    const cursorTimeout = setTimeout(() => {
      setCursorVisible((visible) => !visible);
    }, 500); // Blink every 500 ms

    if (!isDeleting && text === phrases[phraseIndex]) {
      setTimeout(() => setIsDeleting(true), 1000);
    } else if (isDeleting && text === "") {
      setIsDeleting(false);
      setPhraseIndex((prev) => (prev + 1) % phrases.length);
    }

    const timeout = setTimeout(
      () => {
        setText((currentText) =>
          isDeleting
            ? currentText.slice(0, currentText.length - 1)
            : currentText + phrases[phraseIndex].charAt(currentText.length)
        );
      },
      isDeleting ? backSpeed : typeSpeed
    );

    return () => {
      clearTimeout(timeout);
      clearTimeout(cursorTimeout);
    };
  }, [text, isDeleting, phrases, phraseIndex]);

  useEffect(() => {
    // Toggle logo every 3 seconds (adjust timing as needed)
    const intervalId = setInterval(() => {
      setIsTechResourcesLogo((prevLogo) => !prevLogo);
    }, 3000);

    return () => clearInterval(intervalId); // Clean up interval
  }, []);

  const logoVariants = {
    initial: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
    exit: { opacity: 0, x: 20, transition: { duration: 0.8 } },
  };

  const handleLearnMoreClick = () => {
    navigate("/about-us"); // This should match the path you set in App.js
  };

  return (
    <div className="relative text-white" id="hero">
      {/* Background Video */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        autoPlay
        muted
        loop
      >
        <source src="/assets/cyberzoom.mp4" type="video/mp4" />
      </video>

      {/* Black Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div className="relative max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <div className="text-white">

          {/* Animated Logo Container */}
          <motion.div
            className="logo-container"
            variants={logoVariants}
            initial="initial"
            animate="visible"
            exit="exit"
            key={isTechResourcesLogo ? "tech-resources-logo" : "netscout-logo"}
            style={{
              display: "flex", // Enables flexbox
              justifyContent: "center", // Centers items horizontally
            }}
          >
            {isTechResourcesLogo ? (
              <img src={logoImage} alt="Technology Resources Logo" className="logo" />
            ) : (
              <img
    src={netscoutLogo}
    alt="Netscout Arbor Logo"
    className="logo"
    style={{ width: "80%", height: "80%" }} // Adjust width as needed
  />
            )}
          </motion.div>

          <p className="text-[#97d700] font-magistral p-2">
            Technology Resources & Netscout Arbor
          </p>
          <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold font-magistral md:py-6">
            Securing your Network
          </h1>
          <div className="flex justify-center items-center">
            <p className="md:text-5xl sm:text-4xl text-xl font-bold font-magistral py-4">
              Leading in
            </p>
            <p className="md:text-5xl sm:text-4xl text-xl font-light font-magistral md:pl-4 pl-2">
              {text}
              <span className={cursorVisible ? 'blinking-cursor' : ''}>|</span>
            </p>
          </div>
          <p className="md:text-2xl text-xl font-font-magistral text-gray-500">
            Dedicated to safeguarding digital infrastructure and businesses.
          </p>
          <button
            onClick={handleLearnMoreClick}
            className="bg-[#97d700] w-[200px] rounded-md font-magistral my-6 mx-auto py-3 text-black"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
