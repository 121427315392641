// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import PrivacyPolicy from './components/PrivacyPolicy';
import AntiDDoS from './components/antiddos'; // Import the AntiDDoS component
import { ParallaxProvider } from 'react-scroll-parallax';
import Aboutus from './components/Aboutus'; // Import the Aboutus component
import ProfileAli from './components/ProfileAli';
import ProfileZaid from './components/ProfileZaid';
import ProfileMajeed from './components/ProfileMajeed';
import ISP from './components/ISP';
import CybersecurityTimeline from './components/CybersecurityTimeline'; // New component
function App() {
  return (
    <ParallaxProvider>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/anti-ddos" element={<AntiDDoS />} /> {/* New route for AntiDDoS */}
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/profile-ali-m" element={<ProfileAli />} />
          <Route path="/profile-zaid" element={<ProfileZaid />} />
          <Route path="/profile-majeed" element={<ProfileMajeed />} />
          <Route path="/isp" element={<ISP />} />
          <Route path="/cybersecurity-timeline" element={<CybersecurityTimeline/>} /> {/* New route */}


        </Routes>
      </Router>
    </ParallaxProvider>
  );
}

export default App;
