import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Footer from './Footer'; // Import your Footer component
import './PrivacyPolicy.css'; // Your existing CSS file
import logoImage from '../assets/Logo.png'; // Import your logo image


const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const handleHeaderClick = () => {
    navigate('/'); // Redirects to the main page
  };

  return (
    <div>
      <div className="video-header" onClick={handleHeaderClick}>
        <video autoPlay loop muted className="background-video">
          <source src="/assets/cybernet.mp4" type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="header-content">
          <img src={logoImage} alt="Logo" className="header-logo" />
          <h1 className="header-title">Tech Resources</h1>
          </div>
      </div>
          <div>
        <div className="privacy-policy-content">   
        <div className="bg-white text-gray-700 p-8">
  <h1 className="text-2xl font-bold mb-4">Privacy Policy of Resources Technology</h1>
  <p className="mb-4">Effective Date: [Insert Date]</p>
  
  <h2 className="text-xl font-semibold mb-2">1. Introduction</h2>
  <p className="mb-4">
    Resources Technology ("we", "us", "our") is committed to protecting the
    privacy and security of your personal information. This Privacy Policy
    explains how we collect, use, disclose, and safeguard your information when
    you visit our website www.resources-technology.com, use our services, or
    engage with us through other means.
  </p>

  <h2 className="text-xl font-semibold mb-2">2. Information Collection</h2>
  <p className="mb-4">
    We collect information about you in various ways when you interact with our
    services, including:
  </p>
  <ul className="list-disc list-inside mb-4">
    <li>
      <strong>Personal Information:</strong> such as name, email address, phone
      number, and other contact details.
    </li>
    <li>
      <strong>Transactional Information:</strong> details about the transactions
      you conduct using our services.
    </li>
    <li>
      <strong>Technical Information:</strong> IP addresses, browser types, and
      other technical data related to your interaction with our services.
    </li>
  </ul>

  <h2 className="text-xl font-semibold mb-2">3. Use of Information</h2>
  <p className="mb-4">The information we collect may be used for the following purposes:</p>
  <ul className="list-disc list-inside mb-4">
    <li>To provide and improve our services.</li>
    <li>
      To communicate with you, including customer support and promotional
      messages.
    </li>
    <li>To comply with legal obligations and enforce our agreements.</li>
  </ul>


  <h2 className="text-xl font-semibold mb-2">4. Disclosure of Information</h2>
  <p className="mb-4">We may disclose your information:</p>
  <ul className="list-disc list-inside mb-4">
    <li>To service providers and partners who assist us in operating our business.</li>
    <li>
    As required by law, such as to comply with a subpoena, or similar legal process.
    </li>
    <li>In connection with a merger, acquisition, or sale of all or a portion of our assets.</li>
  </ul>
  <h2 className="text-xl font-semibold mb-2">5. Data Security</h2>
  <p className="mb-4">
  We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
  </p>

  <h2 className="text-xl font-semibold mb-2">6. Data Retention</h2>
  <p className="mb-4">
  We will retain your information for as long as your account is active or as needed to provide you services, comply with our legal obligations, resolve disputes, and enforce our agreements.
  </p>

  <h2 className="text-xl font-semibold mb-2">7. Your Rights</h2>
  <p className="mb-4">
  You have certain rights regarding your personal information, including the right to access, correct, or delete your personal data, subject to certain limitations.
  </p>
  <h2 className="text-xl font-semibold mb-2">8. Changes to This Privacy Policy</h2>
  <p className="mb-4">
  We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes, we will notify you by email or by means of a notice on our website prior to the change becoming effective.
  </p>

  <h2 className="text-xl font-semibold mb-2">9. Contact Us</h2>
  <p>
    If you have any questions about this Privacy Policy, please contact us {" "}
    <a href="mailto:info@resources-technology.com" className="text-blue-600 hover:text-blue-800">
      info@resources-technology.com
    </a>.
  </p>
</div>

      </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
  