import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import DDosmain from './components/DDosmain';
import Requestform from './components/Requestform';
import Cards from './components/Cards';
import Footer from './components/Footer';

const MainPage = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <DDosmain />
      <Requestform />
      <Cards />
      <Footer />
    </div>
  );
};

export default MainPage;
