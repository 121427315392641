import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import Footer from './Footer'; // Import your Footer component
import './Aboutus.css'; // Your existing CSS file
import logoImage from '../assets/Logo.png'; // Import your logo image

const Aboutus = () => {
  const navigate = useNavigate();

  const handleHeaderClick = () => {
    navigate('/'); // Redirects to the main page
  };

  return (
    <div className="bg-[#111820] text-[#97d700] font-sans leading-normal tracking-normal">
      <div className="video-header" onClick={handleHeaderClick}>
        <video autoPlay loop muted className="background-video">
          <source src="/assets/cybernet.mp4" type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="header-content">
          <img src={logoImage} alt="Resources Technology Logo" className="header-logo" />
          <h1 className="header-title">Tech Resources</h1>
        </div>
      </div>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold">Who We Are</h2>
        <p className="text-base mt-3">
          Pioneering Iraq's cybersecurity, Resources Technology (TR) is the leading force in crafting secure, innovative solutions. We specialize in comprehensive anti-DDoS strategies in partnership with Netscout Arbor.
        </p>
        <h2 className="text-2xl font-bold mt-4">What We Do</h2>
        <p className="text-base mt-3">
          By collaborating closely with government bodies, TR sets the standard for national cybersecurity protocols, ensuring robust defense across critical infrastructures. Our expertise lies in preempting threats and securing futures.
        </p>
        <h2 className="text-2xl font-bold mt-4">Join Us</h2>
        <p className="text-base mt-3 mb-8">
          TR is not just a company; it's a movement towards a secure digital Iraq. Join us in this journey to define the future of cybersecurity in Iraq and beyond.
        </p>
      </div>
      <Footer />
    </div>
  );
}

export default Aboutus;
