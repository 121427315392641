import React from 'react';
import Footer from './Footer';
import logoImage from '../assets/Logo.png';
import './CybersecurityTimeline.css';
import { useNavigate } from 'react-router-dom';



const CybersecurityTimeline = () => {
    const navigate = useNavigate();
    const handleHeaderClick = () => {
        navigate('/'); // Redirects to the main page
    };

  return (
    <div className="bg-[#111820] text-[#97d700] font-sans leading-normal tracking-normal">
      <div className="video-header" onClick={handleHeaderClick}>
        <video autoPlay loop muted className="background-video">
          <source src="/assets/cybernet.mp4" type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="header-content">
          <img src={logoImage} alt="Resources Technology Logo" className="header-logo" />
          <h1 className="header-title">Tech Resources</h1>
        </div>
      </div>
      <div className="timeline-container mx-auto text-center py-10">
        <h1 className="timeline-title">Cybersecurity Milestones</h1>
        <div className="timeline">
          <div className="timeline-event">
            <h2>2013</h2>
            <p>Starting journey as Hardware & Network Solution Provider</p>
            <ul>
              <li>IVR Platform replacement for TRCL operating for Grameenphone</li>
              <li>Started Software Development with Iraqi Army</li>
            </ul>
          </div>
          <div className="timeline-event">
            <h2>2014</h2>
            <ul>
              <li>Dynamic portal for SoftExpo-2008 awarded by Resources Technology (Tech Resources)</li>
              <li>Expansion in telecom network platform</li>
            </ul>
          </div>
          <div className="timeline-event">
            <h2>2015</h2>
            <ul>
              <li>Released 3rd version of SIGNWRITER for the German company.</li>
              <li>Supplied Hardware and setup for news electronic media agency</li>
            </ul>
          </div>
          <div className="timeline-event">
            <h2>2016</h2>
            <ul>
              <li>Successfully completed Computer Lab Setup under Ministry of telecommunication in 24 districts.</li>
              <li>Timely & successfully commissioned entire back-office IT-infrastructure with all the hardware provided by ATN NEWS & Premier Bank</li>
            </ul>
          </div>
          <div className="timeline-event">
            <h2>2017-2024</h2>
            <ul>
              <li>ITPC Network protection project from DDoS attack:</li>
              <ul>
                <li>Protection of all Iraq's internet bandwidth through IGW devices located in ITPC network sites and then to the ISP</li>
                <li>Hardware installation and programming of Arbor NetScout Anti DDoS equipments</li>
                <li>Building up, Installing and equipping two monitoring and control rooms (SoC)</li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CybersecurityTimeline;
