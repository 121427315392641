import React from 'react';
import { Fade } from 'react-reveal';
import './Profile.css'; 
import logoImage from '../assets/Logo.png'; 
import zaidpf from '../assets/zaidpf.png';
import { useNavigate } from 'react-router-dom'; 

const ProfileZaid = () => {
  const navigate = useNavigate();

  const handleHeaderClick = () => {
    navigate('/'); 
  };

  return (
    <div className="flex flex-col min-h-screen"> {/* Make the main container fill the screen height */}
      <div className="video-header" onClick={handleHeaderClick}>
        <video autoPlay loop muted className="background-video">
          <source src="/assets/cybernet.mp4" type="video/mp4" />
        </video>
        <div className="overlay"></div>
        <div className="header-content">
          <img src={logoImage} alt="Logo" className="header-logo" />
          <h1 className="header-title">Tech Resources</h1>
        </div>
      </div>

      <div className="bg-white flex-grow flex items-center justify-center p-8 text-center w-full"> 
        <div>
          <Fade delay={400}>
            <img src={zaidpf} alt="Zaid" className="profile-image rounded-full w-96 h-96 object-cover mx-auto"/>
          </Fade>
          <Fade delay={800}>
            <h2 className="text-gray-800 text-4xl font-bold mt-4">Zaid Ahmed Jasim</h2>
          </Fade>
          <Fade delay={1200}>
            <h3 className="text-gray-700 text-3xl mt-2">Senior Engineer</h3>
          </Fade>
          <Fade delay={1600}>
            <p className="text-gray-700 text-2xl">Email: zaid@resources-technology.com</p>
          </Fade>
          <Fade delay={2000}>
            <p className="text-gray-700 text-2xl">Phone: +123 456 7890</p>
          </Fade>
          <Fade delay={2400}>
            <p className="text-gray-600 text-xl italic mt-4">"It's all about Mentality"</p>
          </Fade>
        </div>
      </div>

      <div className="video-footer w-full">
        <video autoPlay loop muted className="background-video w-full h-64 object-cover">
          <source src="/assets/cybernet.mp4" type="video/mp4" />
        </video>
        <div className="overlay w-full h-64 absolute top-0 left-0 bg-black opacity-50"></div>
      </div>
    </div>
  );
};

export default ProfileZaid;