import React, { useState, useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-scroll';
import { BsFillMenuButtonWideFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook

import './Navbar.css'

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const sideMenuRef = useRef(null);
  const navigate = useNavigate(); // Initialize the navigate function

  const handleNav = () => {
    setNav(!nav);
  };

  const handleAboutUsClick = () => {
    // Navigate to the About Us page
    navigate('/about-us');
    setNav(false); // Close the side menu if it's open
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
        setNav(false);
      }
    };

    let startX = 0;
    const handleTouchStart = (event) => {
      startX = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
      let endX = event.changedTouches[0].clientX;
      if (startX > endX + 50) { // 50 is a threshold for swipe distance
        setNav(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  return (
    <div className='flex sticky top-0 justify-end items-center h-24 mx-auto px-4 text-white z-50 bg-gradient-to-b from-black to-transparent'>
      <h1 className='w-full font-magistral text-3xl font-bold text-[#97d700]'>Tech Resources</h1>
      <ul className='font-magistral hidden md:flex'>
        <li className='p-4 whitespace-nowrap'><Link to="hero" smooth={true} duration={500} activeClass="active-link" spy={true}>Home</Link></li>
        <li className='p-4 whitespace-nowrap'><Link to="contact" smooth={true} duration={500} activeClass="active-link" spy={true}>Contact us</Link></li>
        <li className='p-4 whitespace-nowrap'><Link to="partners" smooth={true} duration={500} activeClass="active-link" spy={true}>Our Partners</Link></li>
        <li className='p-4 whitespace-nowrap'><Link to="about-us" smooth={true} duration={500} onClick={handleAboutUsClick} activeClass="active-link">About Us</Link></li>
      </ul>
      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <BsFillMenuButtonWideFill size={20} />}
      </div>
      <div
        ref={sideMenuRef}
        className={`fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-100 bg-[#111820] transition-transform duration-300 z-50 ${nav ? 'translate-x-0' : '-translate-x-full'}`}>
        <h1 className='pt-6 w-full font-magistral text-3xl font-bold text-[#97d700] z-50'>Tech Resources</h1>
        <ul className='pt-4 font-magistral uppercase'>
          <li className='p-4 border-b whitespace-nowrap'><Link to="hero" smooth={true} duration={500} onClick={handleNav} activeClass="active-link" spy={true}>Home</Link></li>
          <li className='p-4 border-b whitespace-nowrap'><Link to="contact" smooth={true} duration={500} onClick={handleNav} activeClass="active-link" spy={true}>Contact us</Link></li>
          <li className='p-4 border-b whitespace-nowrap'><Link to="partners" smooth={true} duration={500} onClick={handleNav} activeClass="active-link" spy={true}>Our Partners</Link></li>
          <li className='p-4 border-b whitespace-nowrap' >< Link to="about-us" smooth={true} duration={500} onClick={handleAboutUsClick} activeClass="active-link">About Us</Link></li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar;
