import React from 'react';
import { Link } from 'react-router-dom';
import ISPImage from '../assets/isplog.png';
import MinistryImage from '../assets/Moc.jpg';
import SecurityImage from '../assets/cybersec.png';

const Cards = () => {
  return (
    <div className='w-full py-[10rem] px-4 bg-white' id='partners'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8'>
          <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-white' src={ISPImage} alt="Trusted by Leading ISPs" />
              <h2 className='text-2xl font-bold text-center py-8'>Trusted by Leading ISPs</h2>
              <p className='text-center'>Collaborated with top-tier ISPs to ensure seamless and secure internet experiences.</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Collaborated with top-tier ISPs</p>
                  <p className='py-2 border-b mx-8'>Mitigated large-scale DDoS attacks</p>
                  <p className='py-2 border-b mx-8'>Enhanced network stability</p>
              </div>
              <Link to="/isp" className='bg-[#97d700] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 block text-center'>Learn More</Link>
          </div>
          <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'>
              <img className='w-20 mx-auto mt-[-3rem] bg-transparent' src={MinistryImage} alt="Endorsed by the Ministry" />
              <h2 className='text-2xl font-bold text-center py-8'>Endorsed by the Ministry</h2>
              <p className='text-center'>Worked closely with the Ministry of Telecommunication and the cybersecurity division.</p>
              <div className='text-center font-medium'>
                  <p className='py-2 border-b mx-8 mt-8'>Direct collaboration with government</p>
                  <p className='py-2 border-b mx-8'>Shaping national cybersecurity policies</p>
                  <p className='py-2 border-b mx-8'>Recognized for expertise</p>
              </div>
              <a href="https://itpc.gov.iq/?page=38" target="_blank" rel="noopener noreferrer" className='bg-black text-[#97d700] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 block text-center'>Learn More</a>          </div>
              <div className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300'>
    <img className='w-20 mx-auto mt-[-3rem] bg-white' src={SecurityImage} alt="Pioneers in Cybersecurity" />
    <h2 className='text-2xl font-bold text-center py-8'>Pioneers in Cybersecurity</h2>
    <p className='text-center'>Specializing in security with a dedicated team of experts.</p>
    <div className='text-center font-medium'>
        <p className='py-2 border-b mx-8 mt-8'>Dedicated team of security experts</p>
        <p className='py-2 border-b mx-8'>Continuous R&D in cybersecurity</p>
        <p className='py-2 border-b mx-8'>Tailored security solutions</p>
    </div>
    <Link to="/cybersecurity-timeline" className='bg-[#97d700] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3 block text-center'>Learn More</Link>
</div>
      </div>
    </div>
  );
};

export default Cards;
